import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

//styles
import s from "./MinimalFooter.module.scss";

//assets
import logo from "../../../assets/images/logo/logo-black.png";

//ui
import Container from "../../ui/Containers/container/Contaiter";

//routes
import { paths, pathFC } from "../../../App/Routes/paths";

const MinimalFooter = () => {
	const { t } = useTranslation();

	const date = new Date().getFullYear();

	return (
		<footer className={s.footer}>
			<Container>
				<div className={s.body}>
					<div className={s.text}>
						{date}. {t("footer.copyright")}
					</div>
					<Link
						className={s.logo}
						to={pathFC(paths.main)}
					>
						<img
							src={logo}
							alt="logo"
						/>
					</Link>
				</div>
			</Container>
		</footer>
	);
};

export default MinimalFooter;
